@use "src/css/variables" as *
@use "src/css/graphs/base"
@use 'sass:color'

/**
 * We are not using a css module here since there can be global class applied to this wrapper class.
 * Using a css module would not be possible since the extra global class applied can not be reference
 * like a local class in a css module. Mixing local en global scoped styles is not possible.
 * 
 * Example: <div class="calendar_wrapper small"></div>
 * .small in this case is a class passed down by the parent component and not a local class reference.
 */
.calendar_wrapper
    display: flex
    justify-content: center
    background: white
    border-radius: .5rem
    width: 100%
    overflow: hidden

    .range-presets
        display: flex
        flex-direction: column
        border-right: 1px solid var(--light-grey)

        .pe-button
            color: #333
            margin: 0
            padding: 0

            &:hover
                .pe-button__content
                    border-radius: 0

            &--selected
                pointer-events: all
                cursor: pointer

                .pe-button__content
                    border-radius: 0
                    background: color.adjust($satys-accent, $alpha: -0.8)

            .pe-button__content
                justify-content: flex-start
                padding: 0

                .pe-button__label
                    text-transform: none
                    padding: 1rem 2rem


    .buttons
        width: 100%
        display: flex
        justify-content: flex-end
        gap: 1rem
        padding: 1rem 2rem

        .pe-button__content
            border: 1px solid var(--satys-accent)

        .button-highlight
            .pe-button__content
                color: white
                background-color: var(--satys-accent)
                transition: background-color 150ms ease-in-out

                &:hover
                    background-color: color.adjust($satys-accent, $lightness: -10%)

        .pe-button__label
            text-transform: none

    .calendar
        flex: 1
        border-bottom: 1px solid var(--light-grey)
        padding: 0 2rem

        .pika-single::before,
        .pika-single::after
            content: none
            display: none
            clear: none

        .pika-single.side-by-side
            display: flex
            justify-content: space-between
            gap: 1rem
            width: auto
            padding: 0
            margin: 0
            border: none
            color: #404346
            background: #fff
            z-index: 6500

            &.is-inrange
                &:first-child
                    border-top-left-radius: 100%
                    border-bottom-left-radius: 100%

                &:last-child
                    border-top-right-radius: 100%
                    border-bottom-right-radius: 100%

            .pika-title
                .pika-label
                    color: $satys-accent
                    cursor: pointer

                    &:hover
                        text-decoration: underline

                .pika-next, .pika-prev
                    font-size: 3rem

            .pika-lendar
                width: 100%
                float: none
                padding: 1rem 0
                margin: 0

                &:last-child
                    border-left: 1px solid var(--light-grey)

                .pika-table
                    table-layout: fixed
                    border-collapse: separate
                    border-spacing: 0 2px
                    border: none
                    margin-top: 1rem

                    .pika-row
                        td:nth-child(1 of .is-inrange)
                            border-top-left-radius: 100%
                            border-bottom-left-radius: 100%

                        td:nth-last-child(1 of .is-inrange)
                            border-top-right-radius: 100%
                            border-bottom-right-radius: 100%

                    // Remove the radius from the first element after the start range
                    .pika-row > .is-startrange:has(+ .is-inrange) + .is-inrange
                        border-radius: 0

                    th
                        color: var(--satys-text-color)
                        font-weight: 700

                        abbr
                            text-decoration: none
                            cursor: inherit

                    td
                        width: 50px
                        height: 50px
                        overflow: hidden
                        border-radius: 0

                        &:hover
                            .pika-button
                                border-radius: 0
                                color: var(--satys-accent)

                        &.is-inrange:hover,
                        &.is-startrange:hover
                            .pika-button
                                // color: var(--satys-accent)
                                font-weight: 700

                        &:hover:not(&.pika-week):not(&.is-empty):not(.is-inrange):not(.is-startrange)
                            border-radius: 100%
                            cursor: pointer
                            color: var(--satys-accent)
                            background-color: color.adjust($satys-accent, $alpha: -0.9)

                            .pika-button
                                font-weight: 700
                                color: var(--satys-accent)

                        &.pika-week
                            text-align: center

                        &.is-selected.is-startrange
                            .pika-button
                                color: white
                                background-color: var(--satys-accent)
                                border-radius: 100%

                        &.is-inrange, &.is-startrange
                            .pika-button
                                background-color: color.adjust($satys-accent, $alpha: -0.9)

                        &.is-startrange .pika-button
                            color: white
                            background-color: var(--satys-accent)
                            border-top-left-radius: 100%
                            border-bottom-left-radius: 100%

                        &.is-inrange-last .pika-button
                            color: white
                            background-color: var(--satys-accent)
                            border-top-right-radius: 100%
                            border-bottom-right-radius: 100%

                        .pika-button
                            background: transparent
                            text-align: center
                            height: 100%
                            padding: 10px 5px
                            margin: 0

                        &.is-today:not(.is-inrange):not(.is-startrange):not(.is-inrange-first) .pika-button
                            color: var(--satys-accent)

.calendar_wrapper.small
    .range-presets
        .pe-button
            .pe-button__content
                .pe-button__label
                    padding: 1rem

    .calendar
        padding: 0 1rem

        .pika-single.side-by-side
            .pika-lendar
                .pika-table
                    td
                        height: 40px

    .buttons
        width: 100%
        display: flex
        justify-content: flex-end
        gap: 1rem
        padding: .5rem
