@use "src/css/variables" as *

.feature-notice
  position: absolute
  top: 0px
  bottom: 0px
  right: 0px
  left: 0px
  overflow: hidden
  pointer-events: none

  &.small
    font-size: .6rem

  &.faded
    background-color: rgba(0, 0, 0, 0.025)
    border-radius: .5rem

    ~ *
      filter: grayscale(100%)
      pointer-events: none
      user-select: none

  > div
    box-shadow: 0px 0.125em .25em rgba(0, 0, 0, 0.175)
    width: 15em
    font-weight: bold
    padding: .2em
    text-align: center
    color: $satys-light-accent
    transform: translate(-33%, 100%) rotate(-45deg)

  .notice
    background-color: $satys-accent

    &.new
      background-color: darkgreen

    &.demo
      background-color: $satys-light-purple

    &.coming-soon
      background: $satys-dark-accent

    &.updated
      background: $satys-purple

@media print
  .feature-notice .updated
    box-shadow: none