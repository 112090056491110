@use 'src/css/variables' as *
@use 'src/css/graphs/base'

.type-successes_and_opportunities
  h3
    display: flex

    i
      line-height: .9em
    div
      margin-left: 8px

  .tabs
    .tab-list
      display: grid
      grid-template-columns: repeat(3, 1fr)
      padding: 5px
      background-color: var(--light-grey)
      border-radius: 5px
      margin-bottom: 0.5rem

      .tab-trigger
        display: flex
        align-items: center
        justify-content: center
        width: 100%
        padding: 0.5rem 1rem
        font-size: 0.9rem
        font-weight: 600
        border-radius: 5px
        cursor: pointer
        color: var(--satys-text-color-light)
        background-color: var(--light-grey)
        box-shadow: none
        border: none
        outline: none
        transition-property: background-color, color, box-shadow
        transition-duration: 0.15s
        transition-timing-function: ease-in-out

        &:hover
          color: var(--satys-text-color)

        &.active
          background-color: var(--satys-accent)
          color: #fff
          box-shadow: 0 0 3px 0 var(--satys-text-color-light)
          z-index: 1

    .tab-content
      display: none

      &.active
        display: block

  .list
    border: 1px solid $light-grey
    border-radius: 5px
    overflow: hidden

    .list-headers
      display: grid
      grid-template-columns: 2fr 1fr 1fr 24px
      grid-gap: 1rem
      font-weight: bold
      font-size: 1rem
      padding: .5rem
      background-color: var(--satys-bg-color)

    &--with-rank
      .list-headers,
      ul li a
        grid-template-columns: 2ch 2fr 1fr 1fr 24px

  ul
    padding: 0
    list-style: none
    font-weight: 300
    margin: 0
    max-height: 16rem

    li
      border: 0px solid $light-grey

      &:nth-of-type(even)
        border-top-width: 1px
        border-bottom-width: 1px

      &:last-child
        border-bottom-width: 0px

      a
        display: grid
        grid-gap: 1rem
        grid-template-columns: 2fr 1fr 1fr 24px
        align-items: center
        padding: .5rem
        color: var(--satys-text-color)
        font-weight: 300

        &:last-child
          border-bottom: none

        .rank,
        .name
          text-align: left

        .name
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis

        .response-count,
        .score,
        .arrow
          text-align: right
          white-space: nowrap

        &.click-through
          cursor: pointer
          transition: $transition

          &:hover
            background-color: $satys-accent
            color: white

        .secondary-name
          opacity: 0
          margin-left: .5rem
          transition: $transition

        &:hover
          .secondary-name
            opacity: 1

  .save-as
    .tab-list
      display: none

    ul
      max-height: unset

    .overflow-y-auto
      overflow-y: visible

    .arrow
      display: none


@media print
  .type-successes_and_opportunities
    ul
      max-height: unset

    .overflow-y-auto
      overflow-y: visible

    .arrow
      display: none
