@use 'src/css/variables' as *
@use 'base'

.prioritymatrix
    display: flex
    flex: 1
    flex-direction: row
    flex-wrap: wrap

    .graphcontainer
        display: flex
        flex: 1

    .tooltip
        transform: translate(-50%,calc(-100% - 0.5rem))

        &.x
            transform: translate(-50%)
            @include base.triangle(top, 5px, base.$accent)
        &.y
            transform: translate(-100%,-50%)
            @include base.triangle(right, 5px, base.$accent)

    svg
        .quadrants
            text
                fill: #FFFFFF
            .topleft
                fill: #E7C3C3
            .topright
                fill: #D0DDD0
            .bottomleft
                fill: #EFE0D1
            .bottomright
                fill: #DDDDDD
        circle
                fill: $satys-light-accent
                stroke-width: 2px
        .mouse_lines
            path
                stroke: base.$accent

    .prioritymatrix-legend
        display: flex
        flex: 0 1 auto
        flex-direction: row
        flex-wrap: wrap
        align-items: flex-start

        ul.values
            display: flex
            flex-direction: column
            padding: 0

            li
                flex: 1
                font-size: .9rem
                line-height: 1.5rem
                padding: .5rem
                cursor: default
                border-radius: 8px
                transition: $transition
                min-width: 0

                &.header
                    font-style: italic
                    color: $light-text

                .index
                    transition: $transition
                    border: 1px solid
                    border-color: #eaeaea
                    background-color: #f9f9f9
                    display: inline-block
                    flex-basis: 1.5rem
                    width: 1.5rem
                    height: 1.5rem
                    border-radius: 100%
                    text-align: center
                    line-height: 1.4rem
                    margin-right: .5rem
                    font-weight: bold

                .subject
                    padding-right: 1rem
                    white-space: nowrap
                    overflow: hidden
                    text-overflow: ellipsis
                    cursor: text

                .score
                    padding-left: 1rem
                    margin-left: auto
                    cursor: text

                &.hover
                    background: base.$monochrome_rest_color

                    .index
                        border-color: base.$accent

                &.disabled
                    opacity: 0.5
                    font-style: italic


    svg .dots circle.hover
        stroke: base.$accent

@media screen and (max-width: 1025px)
    .prioritymatrix
        flex-direction: column

@media print
    .prioritymatrix
        flex-wrap: nowrap

        svg .quadrants text
            font-size: 1.5rem

        .prioritymatrix-legend .values
            margin: 0

