.filter_wrapper :global
    position: relative

    .filter-bar
        z-index: 1
        filter: drop-shadow(0px 0.3rem 0.15rem rgba(0, 0, 0, 0.05))
        width: 100%
        transition: opacity 1s ease

        &--fixed
            position: fixed
            top: 3rem
            right: 0
            width: calc(100% - 18rem)
            z-index: 2
            border: none
            filter: none
            border: none
            border-radius: 0
            filter: drop-shadow(0px 0.3rem 0.15rem rgba(0, 0, 0, 0.05))

            & > div
                width: 95%
                max-width: 96rem !important
                margin-left: auto
                margin-right: auto


        .pe-textfield
            padding-bottom: 0

        .filters
            flex: 1

    .placeholder
        position: absolute
        inset: 0
        width: 100%

@media screen and (min-width: 30em)
    .filter_wrapper :global
        .filter-bar--fixed > div
            padding-left: 1rem
            padding-right: 1rem
