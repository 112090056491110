@use 'variables' as *

.analyses-accordion
    .analyses
        .analysis
            flex-basis: 0
            transform: translateX(0)

            &.to-right
                transition: 250ms
                transform: translateX(100%)
            &.to-left
                transition: 250ms
                transform: translateX(-100%)

            .meta
                display: flex
                justify-content: space-between
                margin: .5rem 0px
                color: rgba(0, 0, 0, .4)

            .content
                font-size: 1rem
                line-height: 1.5rem
                overflow-y: auto

    .navigation
        .previous, .next
            top: 50%
            transform: translateY(-50%)

            i.material-icons
                font-size: 2rem

        .progress-bar
            height: .25rem
            .progress
                transition: left 250ms
