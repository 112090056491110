.horizontal-score-bar
    position: relative
    display: flex
    flex-direction: column
    justify-content: center
    width: 100%

    .bar
        height: 10px
        border-radius: 5px
        background: linear-gradient(to right, #AE7F7F, #84AD7F)
        z-index: 1

    .bar-hider
        position: absolute
        height: 9px
        top: -1px
        right: -1px
        border-top-right-radius: 5px
        background-color: #FFFFFF
        z-index: 2

    .label__start,
    .label__end
        position: absolute
        bottom: 0
        font-size: 0.8rem
        color: #aaaaaa
        opacity: 0
        transition: opacity 166ms ease
        transform: translateY(120%)

    .label__start 
        left: 0

    .label__end
        right: 0

    .label__required-value-container
        position: absolute
        bottom: 0
        width: 0
        right: 0
        transform: translateY(55%)
        z-index: 3

        .label__required-value
            font-size: 0.8rem
            color: #aaaaaa
            opacity: 0
            transition: opacity 166ms ease

        .label__required-value-pointer
            width: 0px
            height: 30px
            border: 0 dashed #aaaaaa
            border-right-width: 2px
            z-index: 0

    .label__score-container
        position: absolute
        top: 0
        width: 0
        right: 0
        transform: translateY(-90%)

        .label__score
            font-size: 0.8rem
            color: #505050
            margin-bottom: 2px
            opacity: 0
            transition: opacity 166ms ease
