@use "src/css/variables" as *
@use "src/css/graphs/base"
@use 'sass:color'

.daterange :global
    .date_range
        .pe-button
            &.pe-button--selected
                pointer-events: all
                cursor: pointer

                .pe-button__content
                    background: color.adjust($satys-accent, $alpha: -0.8)

            .pe-button__content
                padding: 0

                .pe-button__label
                    font-size: .9rem
                    line-height: 1
                    padding: .5rem
                    text-transform: none

        .select
            display: inline-block

            .pe-button__content
                padding-right: 1rem

                .pe-button__label:after
                    content: ""
                    position: absolute
                    width: 0
                    height: 0

                    border-width: 6px
                    border-style: solid
                    border-color: transparent

                    right: -0.5rem
                    top: 50%
                    transform: translateY(-50%)
                    border-bottom: none
                    border-top-color: var(--satys-accent)

    .pikaday
        position: absolute
        background: white
        right: 0
        width: 800px
        z-index: 5
        border-radius: .5rem
        box-shadow: 0 0 5px 0 color.adjust(#000000, $alpha: -0.8)
        transform: translateY(3rem)

        &:not(.dn)
            display: flex
