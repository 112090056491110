@use "/src/css/variables" as *

.tinycards
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr))
    gap: 2rem
    margin-bottom: 2rem

    .tinycard
        position: relative
        display: flex
        flex-direction: column
        justify-content: space-between
        padding: 1.5rem 1rem
        background: white
        border-radius: .5rem
        filter: drop-shadow(0 0 0.3rem rgba(0, 0, 0, 0.10))
        will-change: filter // To get safari to show the drop-shadow

        &:hover
            .insight-feedback-wrapper
                display: block

        .leading
            display: block
            font-size: 0.9rem
            color: #aaaaaa

        .value
            display: block
            font-size: 3rem
            line-height: 1
            font-weight: 700
            padding: 1rem 0 0

            &--small
                font-size: 1rem
                font-weight: 400

        .context
            display: block
            margin-top: 0.5rem
            font-size: 0.8rem
            line-height: 1rem
            min-height: 1rem
            opacity: 0
            color: #aaaaaa
            font-weight: 400
            transition: opacity 166ms ease

        .card-info
            position: absolute
            top: 1.25rem
            right: 1rem
            cursor: pointer

        .skeleton
            .skel
                position: absolute
                inset: 0
                margin-top: 1rem
                border-radius: 5px
                overflow: hidden


    &:hover .tinycard
        .context
            opacity: 1

@media screen and (max-width: 1025px)
    .tinycards
        grid-template-columns: repeat(2, minmax(100px, 1fr))

@include small
    .tinycards
        grid-template-columns: repeat(1, 1fr)

@media print
    .tinycards
        .tinycard
            gap: 1.5rem !important
            filter: none
            border: 2px solid $light-grey

            .value
                font-size: 2.5rem
                padding: 0

            .context
                opacity: 1
