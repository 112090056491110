@use 'src/css/variables' as *

.titleseparator
    padding: 2rem 0
    .box
        i.title-icon
            font-size: 4rem
            color: #ba2e6d
        .title-box
            vertical-align: top
            margin-left: 1rem
            .title
                font-size: 2rem
                margin: 0 0 0.5rem 0
