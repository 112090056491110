.type-labels_score

    .card:hover
        .score,
        .label__start,
        .label__end,
        .label__score-container .label__score,
        .label__required-value-container .label__required-value
            opacity: 1

    .label-score-body
        // - 2rem because that is the margin between the body and the card title
        max-height: calc(350px - 2rem)
        overflow-x: hidden
        overflow-y: auto

    .label-score-container
        display: flex
        flex-direction: column
        margin-bottom: 2.5rem

        &.no-data
            margin-bottom: 1.5rem
            padding-right: .5rem

            .label
                margin: 0 0 .5rem 0

    .label
        margin: 0 0 1rem 0
        z-index: 5

    .score
        opacity: 0
        margin: 0 0 1rem 0
        color: #aaaaaa
        font-size: 0.9rem

    .no-data-available
        color: #aaaaaa
        font-style: italic
        font-size: .9rem

@media print
    .type-labels_score
        .label-score-container
            margin-bottom: 3rem

        .label
            margin: 0 0 1.5rem 0
        
        .score,
        .label__start,
        .label__end,
        .label__score-container .label__score,
        .label__required-value-container .label__required-value
            opacity: 1 !important
