.stacked-comparison-graph

  svg .text
    font-size: 0.8rem
    opacity: 0
    pointer-events: none
  &:hover
    svg .text
      opacity: 1
  
  .title
    text-align: center

  .tooltip
    position: absolute
    display: block
    z-index: 10
    pointer-events: none
    white-space: nowrap
    border-radius: 5px
  
  #legend
    .legend-item 
      display: flex 
      flex-direction: row 
      align-items: center
      margin-right: 1rem
      
      .legend-color
        height: 20px
        width: 20px
        margin-right: 5px
        border-radius: 5px

@media print
  .stacked-comparison-graph
    svg .text
      opacity: 1
