.smart_summary :global
  position: relative
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  overflow-y: auto
  padding: 0 20px 20px 32px
  margin-top: 0

  .analysis
    display: flex
    flex-direction: column
    width: 30%
    max-width: 24rem
    padding-right: 2rem
    margin-right: 2rem
    border-right: 1px solid #dedede
    line-height: 1.5
    font-style: italic

    p
      margin: 0

    .greetings
      display: flex
      justify-content: flex-end
      margin-top: 10px

  .entries
    display: flex
    flex-direction: row
    flex-wrap: nowrap
    overflow-y: auto
    width: 100%

    .entry
      display: flex
      flex-direction: column
      align-items: flex-start
      min-width: 40%
      max-width: 24rem
      padding-right: 2rem

      &:not(.entry:last-child)
        margin-right: 2rem
        padding-right: 2rem
        border-right: 1px solid #dedede

      .title
        font-size: 1.5rem
        margin: 0 0 2rem
        font-weight: 700

      .title__highlight
        display: block
        width: 3rem
        height: 3px
        background-color: var(--satys-accent)
        margin-top: 2px

      .score
        font-size: 1.8rem
        font-weight: 300
        line-height: 1

      .scale
        font-size: 1rem
        font-weight: 100
        margin-bottom: 0px

      .score-difference
        display: flex
        align-items: center
        font-size: 1.2rem
        font-weight: 300
        margin: 0

        &--increase
          color: var(--satys-graph-green)

        &--decrease
          color: var(--satys-graph-red)

          svg
            transform: rotateX(180deg)

        svg
          height: 1.4rem
          width: auto
          margin-right: 2px

      .text
        font-size: 1.25rem
        margin: 0 0 0 10px
        font-weight: 700

      .notice
        display: flex
        margin-top: 2rem
        font-size: 1rem
        line-height: 1.25

@media print
  .smart_summary :global
    .entries .entry
      .score-difference
        svg
          height: 1.4rem !important
