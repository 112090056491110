@use 'sass:map'
@use 'src/css/graphs/base'
@use 'src/css/variables' as *

$distance-from-circle: 15px

.timeseries :global
    display: flex
    flex-direction: column

    .tooltip
        position: initial
        font-size: .75rem
        pointer-events: none
        z-index: 1

        &.kpi
            transform: translate(100%, -50%)
            @include base.triangle(left, 5px, base.$accent)

        &.x
            transform: translateX(-50%)
            white-space: nowrap
            @include base.triangle(top, 5px, base.$accent)

        &.y
            display: block
            position: absolute
            white-space: nowrap
            top: 0px

            transform: translate(-50%, -100%)
            @include base.triangle(bottom, 5px, base.$accent)

            tr > td
                padding: .25rem

            .score
                padding-left: 2rem
                font-weight: bold
                text-align: right

            .circle
                display: inline-block
                margin-left: -.25rem
                margin-right: .25rem
                vertical-align: inherit
                border-radius: 100%
                width: 10px
                height: 10px
                background-color: base.$accent
                border-style: solid
                border-width: 1px
                border-color: white

            .bar-legend
                margin-left: -.25rem
                margin-right: .25rem
                width: 10px
                height: 10px
                display: inline-block
                border-style: solid
                border-width: 1px
                border-color: white
                border-radius: 2px
                margin-right: 8px
                background-color: map.get(base.$timeseries-lines, 1)

            @each $value, $color in base.$timeseries-lines
                .line-#{$value} .circle
                    background-color: $color
            [data-key="parent"] .circle
                background-color: base.$timeseries-parent

    .graph-legend
        padding: 0 2rem

        > div
            display: inline-block
            margin-right: 2rem
            margin-bottom: 1rem

            .bar-legend
                background-color: map.get(base.$timeseries-lines, 1)
                opacity: 0.2
            @each $value, $color in base.$timeseries-lines
                // Adding 1 to n since it is preceded by the bar-legend
                &:nth-child(#{$value + 1})
                    .line-legend
                        background-color: $color
            &[data-key="parent"] .line-legend
                background-color: base.$timeseries-parent


        .bar-legend
            height: 1rem
            width: 1rem
            display: inline-block
            margin-right: 8px
            border-radius: 2px

        .line-legend
            height: base.$default-line-width
            border-radius: 8px
            width: 16px
            display: inline-block
            margin-right: 8px
            transform: translateY(-125%)

    svg
        .bars rect
            fill: map.get(base.$timeseries-lines, 1)
            opacity: 0.15
        .line
            stroke-width: 3px
            fill: none
            stroke-linejoin: round
            strok-linecap: square
        .circle
            pointer-events: none
            stroke-width: base.$default-line-width
            fill: white
            stroke: base.$accent
        .hover
            z-index: 1
            line
                stroke-width: 1px
                stroke: base.$accent
        .kpi line
            stroke-width: 1px
            stroke: base.$accent
            stroke-dasharray: 5

        .lines .line
            @each $value, $color in base.$timeseries-lines
                &:nth-child(#{$value})
                    stroke: $color
            &[data-key="parent"]
                stroke: base.$timeseries-parent
                stroke-width: 1px

        .circles circle
            @each $value, $color in base.$timeseries-lines
                // We use n*2 and n*2-1 since 1,2 and 3,4 have to have the same colour
                // and so on
                &:nth-child(#{$value * 2}), &:nth-child(#{$value * 2 - 1})
                    fill: $color
                    stroke-width: 1px
                    stroke: white
                    pointer-events: none
                    &.background:not(.invisible)
                        stroke: none
                        fill: $color
                        opacity: 0.5

            &[data-key="parent"]
                fill: base.$timeseries-parent
                &.background:not(.invisible)
                    stroke: none
                    fill: base.$timeseries-parent
                    opacity: 0.5
