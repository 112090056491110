@use '/src/css/variables' as *
@use '/src/css/graphs/base' as base

.tooltip
    position: relative

    &:hover :global
        .tooltip-content
            opacity: 1
            visibility: visible

    :global
        .tooltip-content
            position: absolute
            opacity: 0
            visibility: hidden
            width: max-content
            max-width: 250px
            z-index: 1000
            padding: 1rem 1.5rem
            color: #ffffff
            border-radius: 5px
            background: $satys-dark-blue
            text-align: center
            pointer-events: none
            transition: opacity 150ms ease-in-out

            &.top
                bottom: calc(100% + 5px)
                left: 50%
                transform: translate(-50%)
                @include base.triangle(bottom, 5px, $satys-dark-blue)

            &.bottom
                top: calc(100% - 5px)
                left: 50%
                transform: translateX(-50%)
                @include base.triangle(top, 5px, $satys-dark-blue)

            &.left
                top: 50%
                left: 0
                transform: translate(-100%, -50%)
                @include base.triangle(right, 5px, $satys-dark-blue)

            &.right
                top: 50%
                left: calc(100% + 10px)
                transform: translateY(-50%)
                @include base.triangle(left, 5px, $satys-dark-blue)
