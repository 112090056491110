.grid_tables :global
    .grid-table
        position: relative
        overflow-y: auto
        // To make the scroll bar not overlay any content within
        padding-right: 10px

        @media print
            max-height: unset !important

        &:hover .grid-row .answer .date
            opacity: 1

        .grid-row
            display: grid
            gap: 1rem
            width: 100%
            padding: 1rem 1rem 1rem 0
            grid-template-columns: 4ch minmax(auto, 45ch)

            &:not(:first-child)
                border-top: 1px solid var(--satys-light-accent)

            &:hover:not(.grid-row--header)
                background-color: var(--satys-light-accent)

            &--header
                position: sticky
                top: 0
                border-top: none
                border-bottom: 1px solid var(--dark-grey)
                background-color: white

            & > div
                display: flex
                flex-direction: column

            .answer
                position: relative
                justify-content: center
                align-items: flex-start
                white-space: break-spaces

                p
                    margin: 0
                    line-height: 1.3

            .sentiment
                justify-content: center
                align-items: flex-end

