.insight-feedback-wrapper
    display: none
    position: absolute
    right: 16px
    top: -16px
    z-index: 4

    .insight-feedback
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        border-radius: 16px
        background-color: white
        box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15)

        button
            display: flex
            align-items: center
            justify-content: center
            height: 32px
            width: 32px
            padding: 5px
            border-radius: 100%
            outline: none
            border: none
            cursor: pointer
            background-color: transparent

            &:hover
                background-color: rgba(0,0,0,.03)

            svg
                color: var(--satys-purple)
                stroke-width: 2px