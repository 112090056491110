@use '/src/css/variables' as *
@use '/src/css/graphs/base'

.histogram
    display: flex
    flex: 1
    flex-direction: row
    flex-wrap: wrap

    .graphcontainer
        display: flex
        flex: 1
        min-width: 600px

    .kpi
        path
            stroke: base.$accent
            stroke-width: 1px
            stroke-dasharray: 5

    .tooltip
        transform: translateX(-50%)
        @include base.triangle(top, 5px, base.$accent)

    .axis.y
        transform: translateX(-.75rem)

    .bars
        rect
            transition: filter .1s ease-in-out

            &.selected
                filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2))

    .legend
        position: relative
        display: flex
        flex: 0 1 auto
        flex-direction: row
        flex-wrap: wrap
        align-items: flex-start
        overflow: hidden auto
        min-width: 300px

        ul.values
            display: flex
            flex-direction: column
            padding: 0
            margin-top: 0
            overflow-y: auto
            width: 100%
            margin-right: 20px

            li
                display: flex
                align-items: center
                flex: 1
                font-size: .9rem
                line-height: 1.5rem
                padding: .5rem
                cursor: default
                border-radius: 8px
                transition: $transition
                min-width: 0

                .index
                    display: flex
                    justify-content: center
                    align-items: center
                    aspect-ratio: 1
                    padding: 0.1rem
                    transition: $transition
                    border: 1px solid
                    border-color: #eaeaea
                    background-color: #f9f9f9
                    flex-basis: 1.5rem
                    border-radius: 100%
                    text-align: center
                    line-height: 1.4rem
                    margin-right: .5rem
                    font-weight: bold

                .subject
                    padding-right: 1rem
                    white-space: nowrap
                    overflow: hidden
                    text-overflow: ellipsis
                    max-width: 200px

                    &:hover
                        white-space: normal

                .score
                    padding-left: 1rem
                    margin-left: auto

                &.hover
                    background: base.$monochrome_rest_color

                    .index
                        border-color: base.$accent

                &.disabled
                    opacity: 0.5
                    font-style: italic
