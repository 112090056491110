@use '../variables' as *

$accent: $satys-dark-blue
$light-text: $light-text

$boolean: (0: #AE7F7F, 1: #84AD7F)
$rating-three: (1: #AE7F7F, 2: #ADAD7F, 3: #84AD7F)
$rating-four: (1: #AE7F7F, 2: #AD967F, 3: #96AD7F, 4: #84AD7F)
$rating-five: (1: #AE7F7F, 2: #AD967F, 3: #ADAD7F, 4: #96AD7F, 5: #84AD7F)
$rating-six: (1: #AE7F7F, 2: #AD967F, 3: #ada77f, 4: #96AD7F, 5: #97C183, 6: #84AD7F)
$rating-seven: (1: #AE7F7F, 2: #AD967F, 3: #ada77f, 4: #ADAD7F, 5: #96AD7F, 6: #97C183, 7: #84AD7F)
$rating-ten: (1: #AE7F7F, 2: #AD967F, 3: #ADA37F, 4: #ada77f, 5: #ADAD7F, 6: #96AD7F, 7: #96AF7D, 8: #97C183, 9: #89B484, 10: #84AD7F)
$rating-eleven: (0: #936868, 1: #AE7F7F, 2: #AD967F, 3: #ADA37F, 4: #ADAD7F, 5: #96AD7F, 6: #84AD7F, 7: #93C47D, 8: #6AA84F, 9: #5c9344, 10: #4e7f39)
$timeseries-parent: #ADB5FF
$timeseries-lines: ( 1: #888888, 2: #DD8888, 3: #88DD88, 4: #8888DD, 5: #DD88DD, 6: #DDDD88, 7: #88DDDD)

$monochrome_relevant_color: #737373
$monochrome_rest_color: #E3E3E3

$default-line-width: .125rem

$transition-props: opacity, border-color, background, background-color
$graph-transition: ''
@each $prop in $transition-props
    $graph-transition: '#{$graph-transition} #{$prop} #{$transition},'
$graph-transition: unquote($graph-transition)

.graphcontainer

    .invisible
        opacity: 0
    .visible
        opacity: 1

    .overlays, .labels, .tooltip, .tick, .mouse_lines
        transition: $graph-transition

    .legend
        cursor: default
        left: 50%
        transform: translateX(-50%)
        font-size: small
        color: $light-text

    .tooltip
        color: white
        padding: 0.25rem 0.5rem
        background: $accent
        transition: $graph-transition

        &.absolute
          position: absolute

svg
    width: 100%
    overflow: visible

    .tick, path, line, .mouse_lines
        pointer-events: none
    .graphgrid, .axis.y .tick line
        color: #CCCCCC
    .axislabels .label
        text-anchor: middle
        fill: $light-text
        font-size: small
        transition: $graph-transition
        &.y
            transform: rotate(-90deg)
    .axis
        color: $light-text
    .domain
        stroke: none
        fill: none

#dashboard
    .form.flex
        > div:not(:last-child)
            margin-right: 2rem

@mixin triangle($direction, $size, $color)
    &:before
        content: ""
        position: absolute
        width: 0
        height: 0

        border-width: $size
        border-style: solid
        border-color: transparent

        @if $direction == 'left'
            right: 100%
            top: 50%
            transform: translateY(-50%)
            border-left: none
            border-right-color: $color

        @if $direction == 'right'
            left: 100%
            top: 50%
            transform: translateY(-50%)
            borer-right: none
            border-left-color: $color

        @if $direction == 'top'
            bottom: 100%
            left: 50%
            transform: translateX(-50%)
            border-top: none
            border-bottom-color: $color

        @if $direction == 'bottom'
            top: 100%
            left: 50%
            transform: translateX(-50%)
            border-bottom: none
            border-top-color: $color
