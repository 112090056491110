@use '../variables' as *
@use 'base'

.horizontal_distribution_bar
    .bars rect
        stroke: white
        stroke-width: 2px

        @each $value, $color in base.$rating-five
            &[data-value="#{$value}"]
                fill: $color

    .type-emoji .overlays text
        font-family: Material Icons
        font-size: 1.75rem
        transform: translateY(14px)

    .overlays
        text
            fill: white
            transform: translateY(5px)

    .labels
        text
            transform: translateY(20px)
