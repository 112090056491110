@use '/src/css/variables' as *

.performance-indicator-info
    :global
        .explanation-smileys
            position: relative
            padding-bottom: 33%
            overflow: hidden

            span
                width: 100%
                height: 100%
                position: absolute
                top: 0
                left: 0
                overflow: hidden

                svg
                    width: 100%
                    height: 100%

                &.overlay
                    z-index: 1

                    &.positive
                        color: $satys-graph-green

                    &.negative
                        color: $satys-graph-red
